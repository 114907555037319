<template>
  <CrudCardTable
    title="Categories"
    :items="instances"
    :headers="headers"
    :button-to="{name: 'App-MenuCreator-Categories-New'}"
  >
    <template #item.name="{ item }">
      <router-link :to="{ name: 'App-MenuCreator-Categories-id', params: { id: item.id } }">
        {{ item.name }}
      </router-link>
    </template>
    <template #item.categoryItems="{ item }">
      <v-chip-group>
        <v-chip
          v-for="categoryItem in item.categoryItems"
          :key="categoryItem.$id"
        >
          {{ categoryItem.item ? categoryItem.item.name : "" }}
        </v-chip>
      </v-chip-group>
    </template>
  </CrudCardTable>
</template>

<script>
import { mapGetters } from 'vuex'
import CrudCardTable from '@/components/CrudCardTable'

export default {
  name: 'CategoryList',
  components: { CrudCardTable },
  data () {
    return {
      headers: [
        { text: 'Name', value: 'name' },
        { text: 'Items', value: 'categoryItems' }
      ]
    }
  },
  computed: {
    ...mapGetters('app', ['categories']),
    instances () {
      return this.categories.all()
    }
  }
}
</script>
